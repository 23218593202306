import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135')
];

export const server_loads = [0,2,21];

export const dictionary = {
		"/(app)": [22,[2],[3]],
		"/(app)/_searches/[slug]": [24,[2],[3]],
		"/(rnd)/_table-legacy": [71,[14,15]],
		"/(rnd)/_table-legacy/settings": [73,[14,15,16]],
		"/(rnd)/_table-legacy/settings/general": [74,[14,15,16]],
		"/(rnd)/_table-legacy/settings/integrations": [75,[14,15,16]],
		"/(rnd)/_table-legacy/[table_id]": [72,[14,15]],
		"/(static)/about": [111,[18],[19]],
		"/(static)/agency": [112,[18],[19]],
		"/(static)/api": [113,[18],[19]],
		"/(static)/api/company": [114,[18],[19]],
		"/(static)/api/person": [115,[18],[19]],
		"/(static)/api/search": [116,[18],[19]],
		"/(static)/automations": [117,[18],[19]],
		"/(static)/(redirect)/billing/plans": [~106,[18],[19]],
		"/(static)/blog": [~118,[18],[19]],
		"/(static)/blog/[tag=blogTag]": [~120,[18],[19]],
		"/(static)/blog/[slug]": [~119,[18],[19]],
		"/(static)/book-demo": [121,[18],[19]],
		"/(app)/campaigns": [25,[2,5],[3]],
		"/(app)/campaigns/[id]/edit": [~26,[2,5,6,7],[3]],
		"/(app)/campaigns/[id]/edit/1-icp": [27,[2,5,6,7],[3]],
		"/(app)/campaigns/[id]/edit/2-offer": [28,[2,5,6,7],[3]],
		"/(app)/campaigns/[id]/edit/3-outreach": [29,[2,5,6,7],[3]],
		"/(app)/campaigns/[id]/edit/4-settings": [30,[2,5,6,7],[3]],
		"/(app)/campaigns/[id]/edit/5-outbox": [31,[2,5,6,7],[3]],
		"/(app)/campaigns/[id]/view": [32,[2,5,6],[3]],
		"/(static)/(redirect)/companies": [~107,[18],[19]],
		"/(static)/(redirect)/companies/[...path]": [~108,[18],[19]],
		"/(app)/dashboard": [~33,[2],[3]],
		"/(static)/explore/companies": [~122,[18],[19]],
		"/(static)/explore/companies/p": [~127,[18],[19]],
		"/(static)/explore/companies/p/[page]": [~128,[18],[19]],
		"/(static)/explore/companies/[slug]": [123,[18,21],[19]],
		"/(static)/explore/companies/[slug]/people": [~124,[18,21],[19]],
		"/(static)/explore/companies/[slug]/people/page/[pageIndex]": [~126,[18,21],[19]],
		"/(static)/explore/companies/[slug]/people/[peopleSlug]": [~125,[18,21],[19]],
		"/(static)/explore/people": [~129,[18],[19]],
		"/(static)/explore/people/p": [~130,[18],[19]],
		"/(static)/explore/people/p/[page]": [~131,[18],[19]],
		"/(static)/(landings)/features/ai-pdf-processing": [96,[18,20],[19]],
		"/(static)/(landings)/features/ai-web-scraping": [97,[18,20],[19]],
		"/(app)/findai": [34,[2],[3]],
		"/(app)/forgot_password": [35,[2],[3]],
		"/(app)/free-chat-pdf": [36,[2],[3]],
		"/(app)/free-chat-pdf/[id]": [37,[2],[3]],
		"/(app)/invitation/accept": [38,[2],[3]],
		"/(app)/invitation/join": [39,[2],[3]],
		"/(static)/jobs": [132,[18],[19]],
		"/(app)/login": [40,[2],[3]],
		"/(app)/notebook": [41,[2],[3]],
		"/(app)/outbox": [42,[2],[3]],
		"/(app)/outbox/messages/[id]": [43,[2],[3]],
		"/(rnd)/outreach": [76,[14]],
		"/(rnd)/outreach/providers/connect": [77,[14]],
		"/(rnd)/outreach/providers/email/connect": [78,[14]],
		"/(rnd)/outreach/providers/new": [79,[14]],
		"/(app)/password_reset": [44,[2],[3]],
		"/(static)/(redirect)/people": [~109,[18],[19]],
		"/(app)/person-finder": [45,[2],[3]],
		"/(static)/plans": [~133,[18],[19]],
		"/(app)/platform": [46,[2],[3]],
		"/(app)/platform/workspaces/new": [51,[2],[3]],
		"/(app)/platform/workspaces/[id]": [47,[2],[3]],
		"/(app)/platform/workspaces/[id]/invitations/new": [50,[2],[3]],
		"/(app)/platform/workspaces/[id]/invitations/[token]/accept": [49,[2],[3]],
		"/(app)/platform/workspaces/[id]/[keys]": [48,[2],[3]],
		"/(static)/policies/privacy": [134,[18],[19]],
		"/(static)/policies/terms": [135,[18],[19]],
		"/(app)/posthog-err": [52,[2],[3]],
		"/(static)/(redirect)/pricing": [~110,[18],[19]],
		"/(app)/referral/[code]": [53,[2],[3]],
		"/(rnd)/rnd": [80,[14]],
		"/(rnd)/sandbox": [81,[14]],
		"/(rnd)/sandbox/amazon_agent": [82,[14]],
		"/(rnd)/sandbox/api_test": [83,[14]],
		"/(rnd)/sandbox/baby_sheet": [~84,[14,17]],
		"/(rnd)/sandbox/baby_sheet/[prompt_slug]": [85,[14,17]],
		"/(rnd)/sandbox/csv_download": [86,[14]],
		"/(rnd)/sandbox/dashboard": [87,[14]],
		"/(rnd)/sandbox/email_search": [88,[14]],
		"/(rnd)/sandbox/enrichment": [89,[14]],
		"/(rnd)/sandbox/keywords": [90,[14]],
		"/(rnd)/sandbox/rewrite_query": [91,[14]],
		"/(rnd)/sandbox/search-viewer": [93,[14]],
		"/(rnd)/sandbox/searchv2_5": [94,[14]],
		"/(rnd)/sandbox/search": [92,[14]],
		"/(rnd)/sandbox/title": [95,[14]],
		"/(app)/searches-dev": [56,[2,9],[3]],
		"/(app)/searches-dev/[slug]": [57,[2,9],[3]],
		"/(app)/search": [54,[2,8],[3]],
		"/(app)/search/[slug]": [55,[2,8],[3]],
		"/(app)/settings": [58,[2,10],[3]],
		"/(app)/settings/account": [59,[2,10],[3]],
		"/(app)/settings/billing": [60,[2,10],[3]],
		"/(app)/settings/provider": [61,[2,10],[3]],
		"/(app)/settings/teams": [62,[2,10],[3]],
		"/(app)/sign_up": [63,[2],[3]],
		"/(app)/table": [64,[2,11],[3]],
		"/(app)/table/settings": [66,[2,11,12],[3]],
		"/(app)/table/settings/general": [67,[2,11,12],[3]],
		"/(app)/table/settings/integrations": [68,[2,11,12],[3]],
		"/(app)/table/[table_id]": [65,[2,11],[3]],
		"/(static)/(landings)/templates": [~98,[18,20],[19]],
		"/(app)/templates/new": [69,[2,13],[3]],
		"/(static)/(landings)/templates/[label]": [~99,[18,20],[19]],
		"/(static)/(landings)/use-cases": [100,[18,20],[19]],
		"/(static)/(landings)/use-cases/__ai-ecommerce-automation": [101,[18,20],[19]],
		"/(static)/(landings)/use-cases/__ai-lead-search": [102,[18,20],[19]],
		"/(static)/(landings)/use-cases/ai-data-cleaning": [103,[18,20],[19]],
		"/(static)/(landings)/use-cases/ai-ecommerce-automation": [104,[18,20],[19]],
		"/(static)/(landings)/use-cases/recruiting-ai-resume-screening": [105,[18,20],[19]],
		"/(app)/users/[id]/set_password/[token]": [70,[2],[3]],
		"/(app)/[...path]": [23,[2],[3,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';