// src/lib/ghost.ts
import GhostContentAPI from "@tryghost/content-api";
import * as GhostTypes from "@tryghost/content-api";

// Simple cache implementation
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cache = new Map<string, { data: any; timestamp: number }>();
const CACHE_TTL = 5 * 60 * 1000; // 5 minutes cache

// Function to create a key from URL and params
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createCacheKey = (url: string, params: Record<string, any>) => {
  return `${url}:${JSON.stringify(params)}`;
};

// Enhanced fetch with retry, timeout and caching
const enhancedFetch = async (
  url: string,
  options: RequestInit,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: Record<string, any>,
  retries = 3,
  timeout = 25000,
  useCache = true,
) => {
  const cacheKey = createCacheKey(url, params);

  if (useCache) {
    const cachedData = cache.get(cacheKey);
    if (cachedData && Date.now() - cachedData.timestamp < CACHE_TTL) {
      return { data: cachedData.data };
    }
  }

  let lastError;

  for (let attempt = 0; attempt < retries; attempt++) {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), timeout);

    try {
      const response = await fetch(url, {
        ...options,
        signal: controller.signal,
      });

      clearTimeout(timeoutId);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (useCache) {
        cache.set(cacheKey, { data, timestamp: Date.now() });
      }

      return { data };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      clearTimeout(timeoutId);
      lastError = error;

      if (
        attempt >= retries - 1 ||
        !(error.name === "AbortError" || error.code === "UND_ERR_CONNECT_TIMEOUT" || error.message?.includes("timeout"))
      ) {
        break;
      }

      const delay = 1000 * Math.pow(2, attempt);
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }

  throw lastError;
};

// Create the enhanced Ghost client
export const ghost = new GhostContentAPI({
  url: "https://find-ai.ghost.io",
  key: "4fc859cc762380ff11197e7663",
  version: "v5.0",
  // Enhanced request function with caching, retries and increased timeout
  makeRequest: ({ url, method, params, headers }) => {
    const apiUrl = new URL(url);

    Object.keys(params).forEach((key) => {
      apiUrl.searchParams.set(key, params[key]);
    });

    // Critical content can skip cache
    const skipCache = url.includes("/posts.read") || url.includes("/pages.read");

    return enhancedFetch(
      apiUrl.toString(),
      { method, headers },
      params,
      3, // 3 retries
      25000, // 25 second timeout
      !skipCache, // Use cache for everything except individual post/page reads
    );
  },
});

const preloadCommonData = async () => {
  try {
    await Promise.allSettled([
      ghost.tags.browse({ filter: "visibility:public" }),
      ghost.posts.browse({
        filter: "featured:true",
        limit: 1,
        include: ["tags", "authors"],
      }),
      ghost.posts.browse({
        limit: 9,
        include: ["tags", "authors"],
        filter: "featured:false",
      }),
    ]);
  } catch (error) {
    console.log("Background preload error (non-critical):", error);
  }
};

preloadCommonData();

export type GhostPost = GhostTypes.PostOrPage;
export type GhostPosts = GhostTypes.PostsOrPages;
